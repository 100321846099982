import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { parseISO } from 'date-fns';
import { format as formatTz, toZonedTime } from 'date-fns-tz';

interface ApiResponse {
  date: string;
  net_sales: number;
  hst: number;
  rebate8: number;
  statusIndianRebate8: number;
  gross_sales: number;
  actual_dpst: number | null;
  payments_by_tender: {
    tender_desc: string;
    total_payment_amount: number | null;
    amex_disc: number | null;
  }[];
}

// Define the corporate numbers by store location
const corporateNumbers: { [key: string]: string } = {
  'Alliston': '2227346 Ontario Inc. - SVP Sports',
  'Brampton': '2861347 Ontario Inc. - SVP Sports',
  'Cartwright': '1192229 Ontario Inc. - SVP Sports',
  'Dufferin-Orfus': '1000829489 Ontario Inc. - SVP Sports',
  'Newmarket': '1000476793 Ontario Inc. - SVP Sports',
  'Niagara': '2473421 Ontario Inc. - SVP sports',
  'OPM-Hamilton': '2806122 Ontario Inc. - SVP sports',
  'Pickering': '1399663 Ontario Inc. - SVP Sports',
  'New-Scarborough': '1000459807 Ontario Inc. - SVP Sports',
  'Steeles': '1125151 Ontario Ltd. - SVP Sports',
  'Vaughan': '2081405 Ontario Inc. - SVP Sports',
  'Yorkgate': '794377 Ontario Ltd. - SVP Sports',
  'Queensway': '1000829490 Ontario Inc. - SVP Sports'
};

export const exportToExcel = async (
  data: ApiResponse[],
  tenderHeaders: string[],
  calculateTotals: { [key: string]: number },
  storeName: string,
  month: string
) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('EOD Data');
  const timeZone = 'America/Toronto';

  // Parse dates and set up date range
  const dates = data.map(d => toZonedTime(parseISO(d.date), timeZone));
  const startDate = new Date(Math.min(...dates.map(d => d.getTime())));
  const endDate = new Date(Math.max(...dates.map(d => d.getTime())));

  const formattedStartDate = formatTz(startDate, 'MMMM d', { timeZone });
  const formattedEndDate = endDate.getDate() === startDate.getDate()
    ? ''
    : `-${formatTz(endDate, 'd', { timeZone })}`;
  const formattedYear = `, ${formatTz(startDate, 'yyyy', { timeZone })}`;
  const dateRange = `${formattedStartDate}${formattedEndDate}${formattedYear}`;

  const mainTitle = 'Sales Register';
  const corporateNumber = corporateNumbers[storeName] || 'SVP Sports';

  // Calculate the total columns dynamically, including tender headers and fixed columns
  const totalColumns = 10 + tenderHeaders.length;
  const lastColumnLetter = worksheet.getColumn(totalColumns).letter;

  // Merged Title
  worksheet.mergeCells(`A1:${lastColumnLetter}1`);
  worksheet.getCell('A1').value = mainTitle;
  worksheet.getCell('A1').font = { bold: true, size: 16 };
  worksheet.getCell('A1').alignment = { horizontal: 'center' };
  worksheet.getCell('A1').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '9BBB59' } };

  // Merged Corporate Number
  worksheet.mergeCells(`A2:${lastColumnLetter}2`);
  worksheet.getCell('A2').value = corporateNumber;
  worksheet.getCell('A2').font = { bold: true, size: 16 };
  worksheet.getCell('A2').alignment = { horizontal: 'center' };
  worksheet.getCell('A2').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '9BBB59' } };

  // Merged Date Range
  worksheet.mergeCells(`A3:${lastColumnLetter}3`);
  worksheet.getCell('A3').value = dateRange;
  worksheet.getCell('A3').font = { bold: true, size: 14 };
  worksheet.getCell('A3').alignment = { horizontal: 'center' };
  worksheet.getCell('A3').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '9BBB59' } };

  // Main Table Header row
  const headerRow = [
    'Date', 'Net Sales', 'Taxes', 'Rebate 8%', 'Status Indian Rebate 8%', 'Total Sales',
    ...tenderHeaders, 'Amex Disc', 'Total RCVD', 'Pay-Out', 'Over/Short'
  ];
  worksheet.addRow(headerRow);

  worksheet.getRow(4).font = { bold: true };
  worksheet.getRow(4).alignment = { horizontal: 'center' };
  worksheet.getRow(4).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFF00' } };

  // Helper function to format currency or display "-" for zero or null values
  const formatCurrency = (value: number | null | undefined) =>
    value && value !== 0 ? parseFloat(value.toFixed(2)) : '-';

  // Add data rows
  data.forEach((day) => {
    const row = [
      day.date, // Keeping date as is
      formatCurrency(day.net_sales),
      formatCurrency(day.hst),
      formatCurrency(day.rebate8),
      formatCurrency(day.statusIndianRebate8),
      formatCurrency(day.gross_sales),
      ...tenderHeaders.map(header => {
        const tender = day.payments_by_tender.find(t => t.tender_desc.toLowerCase() === header.toLowerCase());
        return formatCurrency(tender?.total_payment_amount);
      }),
      formatCurrency(day.payments_by_tender.find(t => t.tender_desc.toLowerCase() === 'amex')?.amex_disc),
      formatCurrency(day.payments_by_tender.reduce((sum, t) => sum + (t.total_payment_amount || 0), 0)),
      formatCurrency(day.actual_dpst),
      formatCurrency(day.gross_sales - day.payments_by_tender.reduce((sum, t) => sum + (t.total_payment_amount || 0), 0))
    ];
    worksheet.addRow(row);
  });

  // Add totals row
  const totalsRow = [
    'Total',
    formatCurrency(calculateTotals.net_sales),
    formatCurrency(calculateTotals.hst),
    formatCurrency(calculateTotals.rebate8),
    formatCurrency(calculateTotals.statusIndianRebate8),
    formatCurrency(calculateTotals.gross_sales),
    ...tenderHeaders.map(header => formatCurrency(calculateTotals[header] || 0)),
    formatCurrency(calculateTotals.amex_disc),
    formatCurrency(calculateTotals.total_rcvd),
    formatCurrency(calculateTotals.actual_dpst),
    formatCurrency(calculateTotals.over_short)
  ];
  worksheet.addRow(totalsRow);

  // Style totals row
  const lastRow = worksheet.lastRow;
  if (lastRow) {
    lastRow.font = { bold: true };
  }

  // Adjust column widths
  worksheet.columns.forEach((column, index) => {
    column.width = index === 0 ? 10 : 12;
    if (index > 0) {
      column.numFmt = '$#,##0.00'; // Set number format for currency
    }
  });

  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  saveAs(blob, `${storeName}EOD-${month}.xlsx`);
};
